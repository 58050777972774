.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Basic reset for body margin to ensure consistent layout */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App-header {
  /* Adjusted header styles if needed */
  text-align: center;
  padding: 20px;
}

/* Style for main content area */
.App-content {
  /* Ensures the content area is flexible */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto; /* Allows for vertical scrolling if the image is tall */
}

/* Updated style for the image */
.responsive-image {
  width: 100%; /* Makes the image responsive, will scale with the container */
  max-width: 1000px; /* Default maximum width the image can grow to */
  height: auto; /* Maintains aspect ratio */
  margin: 20px auto; /* Adds some spacing around the image and centers it horizontally */
}

/* Media query for screens wider than 1920px */
@media (min-width: 1921px) {
  .responsive-image {
    max-width: 1500px; /* Allows the image to grow up to 1500px on larger screens */
  }
}
